import { lazy } from 'react'

const ReportIndex = lazy(() => import('../../views/reports/index'))

const TaskReportIndex = lazy(() => import('../../views/reports/task/index'))
const TaskReportListIndex = lazy(() => import('../../views/reports/task/list/index'))
const TaskBilling = lazy(() => import('../../views/reports/task/taskbilling/index'))

const AttendanceReportIndex = lazy(() => import('../../views/reports/attendance/summary/index'))
const AttendanceListReportIndex = lazy(() => import('../../views/reports/attendance/list/index'))
const TimeSheetReportIndex = lazy(() => import('../../views/reports/timesheet/index'))
const TimeSheetMonthlyReport = lazy(() => import('../../views/reports/timesheet/timesheetlog/index'))

const AttendanceDailyReportIndex = lazy(() => import('../../views/reports/timesheet/attendance/index'))
const DSCReportIndex = lazy(() => import('../../views/reports/dsc/index'))
const LicenseReportIndex = lazy(() => import('../../views/reports/client-license/index'))

const SalesBy = lazy(() => import('../../views/reports/sales/salesdetail/index'))
const Receivables = lazy(() => import('../../views/reports/sales/receivables/index'))
const PaymentReceived = lazy(() => import('../../views/reports/sales/paymentreceived/index'))
const SalesDetail = lazy(() => import('../../views/reports/sales/salesdetail/index'))
const Proposal = lazy(() => import('../../views/reports/sales/proposal/index'))

const Sales = lazy(() => import('../../views/reports/sales/list/index'))
const Performance = lazy(() => import('@src/views/reports/performance/index.js'))
const RevenueReport = lazy(() => import('../../views/reports/sales/revenuebyuser/index'))
const ReimbursementReport = lazy(() => import('../../views/reports/sales/reimbursement/index'))

const SalesReportByOrg = lazy(() => import('@src/views/reports/salesreport/list/index'))
const SalesReportEmployee = lazy(() => import('@src/views/reports/salesreport/employeelist/index'))

const ReportsRoutes = [
  {
    path: '/reports',
    element: <ReportIndex />,
    resource: 'reports'
  },
  {
    path: '/salesreportbyorganization',
    element: <SalesReportByOrg />,
    resource: 'reports'
  },
  {
    path: '/employeelist',
    element: <SalesReportEmployee />,
    resource: 'reports'
  },
  {
    path: '/reports/task-report/:filter',
    element: <TaskReportListIndex />,
    resource: 'reports'
  },
  {
    path: '/reports/task/:filter/:id',
    element: <TaskReportIndex />,
    resource: 'reports'
  },
  {
    path: '/reports/taskbilling',
    element: <TaskBilling />,
    resource: 'reports'
  },
  {
    path: '/reports/attendance/summary',
    element: <AttendanceReportIndex />,
    resource: 'reports'
  },
  {
    path: '/reports/attendance/:id',
    element: <AttendanceListReportIndex />,
    resource: 'reports'
  },
  {
    path: '/reports/timesheetlogs/:id',
    element: <TimeSheetReportIndex />,
    resource: 'reports'
  },
  {
    path: '/reports/timesheetlogs-mothly',
    element: <TimeSheetMonthlyReport />,
    resource: 'reports'
  },
  {
    path: '/reports/attendancedaily',
    element: <AttendanceDailyReportIndex />,
    resource: 'reports'
  },
  {
    path: '/reports/dsc',
    element: <DSCReportIndex />,
    resource: 'reports'
  },
  {
    path: '/reports/client-license',
    element: <LicenseReportIndex />,
    resource: 'reports'
  },
  {
    path: '/reports/salesby/:option',
    element: <SalesBy />,
    resource: 'reports'
  },
  {
    path: '/reports/salesreport/receivables',
    element: <Receivables />,
    resource: 'reports'
  },
  {
    path: '/reports/salesreport/proposals',
    element: <Proposal />,
    resource: 'reports'
  },
  {
    path: '/reports/salesreport/paymentreceived',
    element: <PaymentReceived />,
    resource: 'reports'
  },
  {
    path: '/reports/salesdetail/:id/:name',
    element: <SalesDetail />,
    resource: 'reports'
  },
  {
    path: '/reports/sales',
    element: <Sales />,
    resource: 'reports'
  },
  {
    path: '/reports/salesreport/revenuebyuser',
    element: <RevenueReport />,
    resource: 'reports'
  },
  {
    path: '/reports/salesreport/reimbursement',
    element: <ReimbursementReport />,
    resource: 'reports'
  },
  {
    path: 'reports/performance',
    element: <Performance />,
    resource: 'reports'
  }
]

export default ReportsRoutes
